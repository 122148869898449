
import FacebookIcon from "~/static/icons/FRANK_Icons_FB.svg?inline";
import InstagramIcon from "~/static/icons/FRANK_Icons_IG.svg?inline";
import CatalogueIcon from "~/static/icons/Catalogue.svg?inline";
import MailIcon from "~/static/icons/FRANK_Icons_MAIL.svg?inline";
import MinistryIcon from "~/static/icons/MINISTRY.svg?inline";
import KABIcon from "~/static/icons/KAB.svg?inline";
import SABIcon from "~/static/icons/SAB.svg?inline";

export default {
  name: "Footer",
  components: {FacebookIcon, InstagramIcon, MailIcon, CatalogueIcon, MinistryIcon, KABIcon, SABIcon},
  data() {
    return {
      pages: {
        data: [
          {id: 6, attributes: {name: 'team', href: '/team'}},
          {id: 7, attributes: {name: 'partners', href: '/partners'}},
          {id: 8, attributes: {name: 'resources', href: '/resources'}},
        ],
      },
      year: null
    };
  },
  mounted() {
    const now = new Date()
    this.year = now.getFullYear()
  },
}
